import React from "react"
import { Document, Page } from 'react-pdf';

const Cv: React.FC = (): JSX.Element => {
  return (
    <div>

    </div>
  )
}

export default Cv
